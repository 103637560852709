import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home')
    },
    {
        path: '/aboutus',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
    },
    {
        path: "/disclosure",
        name: "Disclosure",
        component: () => import('../views/disclosure')
    },
    {
        path: "/refund",
        name: "Refund",
        component: () => import('../views/refund')
    },
    {
        path: "/tc",
        name: "Terms and Conditions",
        component: () => import('../views/tc')
    },
    {
        path: "/thankyou",
        name: "Thank You",
        component: () => import('../views/thanks')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: {id: "UA-190753198-1"}
}, router);

export default router
